.container-before-after-sample {
  padding: 24*$pxToVw 0 50*$pxToVw 0;

  .grid-2-col {
    gap: 168*$pxToVw;
    margin-top: 70*$pxToVw;
  }

  @include media-lg-monitor-only {
    padding: 24*$pxToRem 0 50*$pxToRem 0;

    .grid-2-col {
      gap: 168*$pxToRem;
      margin-top: 70*$pxToRem;
    }
  }

  @include media-below-desktop {
    padding: 10*$pxToRem 0 0 0;

    .grid-2-col {
      gap: 24*$pxToRem;
      grid-template-columns: 1fr;
      margin: 24*$pxToRem auto 0 auto;
    }
  }

  figure {
    display: grid;
    grid-template-columns: 1fr 1fr;

    img {
      display: block;
      width: 100%;
    }

    .container {
      svg {
        position: absolute;
        display: block;
        height: 20*$pxToRem;
        width: 39*$pxToRem;
        top: 20*$pxToRem;
        left: 20*$pxToRem;
      }

      & + .container svg {
        width: 30*$pxToRem;
      }
    }
  }
}
