// *********************************************************
// PATHS
// *********************************************************

// output path for CSS background images
$imagesPath: '/wp-content/themes/seychellesmedspa_com/images/';

// output path for web fonts
$fontsPath: '/wp-content/themes/seychellesmedspa_com/fonts/';


// *********************************************************
// COLOR
// *********************************************************

// base colors
$color-black: #000000;
$color-white: #ffffff;

// gray colors
$color-gray-1: #2B2B2B;
$color-gray-2: #5c5c5c;
$color-gray-3: #a5a5a5;
$color-gray-4: #aaaaaa;
$color-gray-5: #bebebe;
$color-gray-6: #c4c4c4;
$color-gray-7: #cccccc;
$color-gray-8: #e0e0e0;
$color-gray-9: #f0f0f0;
$color-gray-10: #f2f2f2;
$color-gray-11: #f7f7f7;

// theme colors
$color-page-bg: $color-white;
$color-accent: #2ACBDD;
$color-texture-light: #faf9f9;
$color-texture-dark: #212121;
$color-texture-ocean: #c1d5d7;
$color-nav-idle: #757575;
$color-text: $color-black;
$color-link: $color-text;
$color-link-hover: $color-accent;

$color-form-placeholder: rgb(118,118,118);

// inverted colors
$color-text-inverted: $color-white;
$color-inverted-link: $color-white;
$color-inverted-link-hover: $color-accent;


// *********************************************************
// TYPOGRAPHY
// *********************************************************

$content-spacing: 30;
$content-spacing-mobile: $content-spacing * 0.8;
$border-thickness: 6;


// body typography values

$font-size-body: 17;
$font-weight-body: 300;
$tracking-body: 50;
$line-height-body: 31;

@mixin getFontProperties($font-size, $font-weight, $tracking, $line-height) {
  font-size: $font-size*$pxToRem;
  font-weight: $font-weight;
  letter-spacing: getLetterSpacing($tracking);
  line-height: getLineHeight($font-size, $line-height);
}

@mixin config-type-body {
  @include util-antialiasing-crisp;
  @include getFontProperties(
    $font-size-body,
    $font-weight-body,
    $tracking-body,
    $line-height-body
  );
  font-family: roboto, sans-serif;
  color: $color-text;

  @include media-mobile-only {
    font-size: 18*$pxToRem;
    line-height: 1.33;
  }
}

@mixin config-type-body-lg {
  font-family: roboto, sans-serif;
  @include getFontProperties(
    20,
    700,
    50,
    32
  );
  text-transform: uppercase;
}

// heading typography values

$font-size-heading: 40;
$font-weight-heading: 400;
$tracking-heading: 160;
$line-height-heading: 50;
// Compensating for lineheight
$visual-font-size-heading: 30;
$offset-heading: getElementOffset($line-height-heading, $visual-font-size-heading);

// h1 and h2 typically
@mixin config-type-heading {
  @include getFontProperties(
    $font-size-heading,
    $font-weight-heading,
    $tracking-heading,
    $line-height-heading
  );
  font-family: commuters-sans, sans-serif;
  text-transform: uppercase;

  @include media-mobile-only {
    font-size: 24*$pxToRem;
    letter-spacing: 0;
    line-height: 1.25;
  }
}

// heading-lg typography values
$font-size-heading-lg: 80;
$font-weight-heading-lg: 400;
$tracking-heading-lg: 160;
$line-height-heading-lg: 100;
// Compensating for lineheight
$visual-font-size-heading-lg: 56;
$offset-heading-lg: getElementOffset($line-height-heading-lg, $visual-font-size-heading-lg);

// h1 and h2 typically
@mixin config-type-heading-lg {
  @include getFontProperties(
    $font-size-heading-lg,
    $font-weight-heading-lg,
    $tracking-heading-lg,
    $line-height-heading-lg
  );
  font-family: commuters-sans, sans-serif;
  text-transform: uppercase;

  @include media-mobile-only {
    font-size: 36*$pxToRem;
    line-height: 1.25;
  }
}

// heading-lg typography values
$font-size-heading-sm: 30;
$font-weight-heading-sm: 400;
$tracking-heading-sm: 30;
$line-height-heading-sm: 45;
$offset-heading-sm: getElementOffset($line-height-heading-sm, $font-size-heading-sm);

// h1 and h2 typically
@mixin config-type-heading-sm {
  @include getFontProperties(
    $font-size-heading-sm,
    $font-weight-heading-sm,
    $tracking-heading-sm,
    $line-height-heading-sm
  );
  font-family: Roboto, sans-serif;
  text-transform: none;
  color: $color-accent;

  @include media-mobile-only {
    font-size: $font-size-heading-sm*$pxToRem;
    line-height: getLineHeight($font-size-heading-sm, $line-height-heading-sm);
  }
}

// subheading-lg typography values
// resource box / larger h3
@mixin config-type-subheading-lg {
  font-size: 26*$pxToRem;
  font-weight: 500; // medium
  letter-spacing: 80*$trackingToLetterSpacing;
  line-height: 30*$pxToRem;
  text-transform: uppercase;
}

// subheading typography values

$font-size-subheading: 20;
$font-weight-subheading: 300;
$tracking-subheading: 160;
$line-height-subheading: 36;
// Compensating for lineheight
$visual-font-size-subheading: 17;
$offset-subheading: getElementOffset($line-height-subheading, $visual-font-size-subheading);
$offset-subheading-micro: getElementOffset($line-height-subheading, $font-size-subheading);

// subheading / h3
@mixin config-type-subheading {
  @include getFontProperties(
    $font-size-subheading,
    $font-weight-subheading,
    $tracking-subheading,
    $line-height-subheading
  );
  font-family: commuters-sans, sans-serif;
  text-transform: uppercase;

  @include media-mobile-only {
    line-height: 1.25;
  }
}

// footer text
@mixin config-type-fine-print {
  font-size: 14*$pxToRem;
  font-weight: 400;
  letter-spacing: 30;

  @include media-mobile-only {
    font-size: 16*$pxToRem;
  }
}

// Outline Heading
@mixin config-type-ornamental {
  // font-family: "Twister", sans-serif;
  // font-size: 58*$pxToRem;
  // font-weight: 400; // regular
  // letter-spacing: 0;
  // line-height: 38*$pxToRem;
  // text-transform: lowercase;
  // color: $color-accent;
  font-family: commuters-sans, sans-serif;
  -webkit-text-fill-color: $color-white;
  -webkit-text-stroke-width: 1*$pxToRem;
  -webkit-text-stroke-color: $color-accent;
}

// *********************************************************
// SIZE
// *********************************************************

// TODO: depcrecated pattern. Remove in favor of gutter, breakpoint, or width values
$size-sm: 24*$pxToRem;
$size-md: 540*$pxToRem;
$size-xl: 1328*$pxToRem;


// *********************************************************
// PADDING
// *********************************************************

$gutter-size-16: 16*$pxToRem;
$gutter-size-24: 24*$pxToRem;
$gutter-size-30: 30*$pxToRem;

$padding-mobile: 24px;

$padding-sm: $size-sm;
$padding-md: 60; //px
$padding-lg: 80; //px
$padding-xl: 140; //px
$padding-xxl: 200; //px

// vertical padding
$padding-top-md: 50;

$padding-default: 76; //px
$padding-default-vw: $padding-default*$pxToVw;
$padding-default-rem: $padding-default* $pxToRem;

$padding-thick-vw: calc(#{120.96*$pxToVw} + #{$gutter-size-16});
$padding-thick-neg-vw: calc(#{-120.96*$pxToVw} - #{$gutter-size-16});
$padding-thick-rem: 137*$pxToRem;
$padding-thick-neg-rem: -137*$pxToRem;




// *********************************************************
// TRANSITION PROPERTIES
// *********************************************************

// general transition properties
$transition-default: 0.2s ease-in;

// animate on scroll config
$aos-duration: 0.7s;
// $aos-slide-distance: 80*$pxToRem;
$aos-slide-distance: 10vh;

// fade transition
$transition-aos-opacity:
  $aos-duration // duration in seconds
  ease // timing function
  $aos-duration * 0.2; // delay in seconds

// slide-up transition
$transition-aos-transform:
  $aos-duration * 0.85 // duration in seconds
  cubic-bezier(0.000, 1.100, 0.985, 0.985); // timing function


// *********************************************************
// PAGE ZOOM
// *********************************************************

// This percentage value is set on root html font-size rule
// to zoom anything using rem units When this is set to 100%
// 1 rem is equal to 16px
$pageZoom: 100%;

// *********************************************************
// SPECIAL
// *********************************************************


// Used to add hash mark to <hr>
// SVG has to be defined in CSS since it's using background: url();
@function getHrBgImageData($color) {
  @return 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 30" style="transform:rotate(45deg);fill:#{$color};"><rect width="1" height="30" /></svg>';
}

// Used to add dropdown triangle to <select>
@function getSelectBgImageData($color: rgb-format($color-form-placeholder)) {
  @return 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 9"><polygon points="15.636,0.225 8.5,7.361 1.364,0.225 0.657,0.932 7.793,8.068 8.5,8.775 9.207,8.068 16.343,0.932" style="fill:#{$color};"/></svg>';
}

@mixin config-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@mixin config-fixed-menu($color) {
  position: fixed;
  z-index: 8;
  left: 0;
  width: 100%;
  background: rgba($color, 0.975);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  filter: drop-shadow(0 0 12*$pxToRem rgba(30,10,0,0.125));
}

@mixin config-button-styles {
  @include util-antialiasing-default;
  display: block;
  text-transform: uppercase;
  transition: background-color $transition-default,
              color $transition-default;
  padding: 20*$pxToRem 36*$pxToRem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 400*$pxToRem;
}

@mixin config-button-black {
  @include config-button-styles;
  color: $color-text-inverted;
  background: $color-black;

  &:hover {
    background: $color-accent;
    color: $color-text;
  }
}

@mixin config-read-more-mobile-button {
  @include config-button-black;
  width: auto;
  height: auto;
  margin-top: 20*$pxToRem;

  svg,
  .read-more__circle,
  .read-more__text,
  .read-more__arrow {
    display: none !important;
  }
}

@mixin config-button-accent {
  @include config-button-styles;
  color: $color-text-inverted;
  background: $color-accent;

  &:hover {
    background: $color-text;
  }
}

@mixin config-ornament {
  content: '';
  display: block;
  width: 14*$pxToRem;
  height: 14*$pxToRem;
  background: $color-accent;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@mixin config-background-box {
  position: absolute;
  z-index: 0;
  width: 398*$pxToVw;
  height: 65%;
  max-height: 614*$pxToRem;

  &:before {
    opacity: 0.7;
  }
}